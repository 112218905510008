import React from "react"

import SEO from "src/components/seo"
import Layout from "src/components/layout"
import GradientsNew from "src/components/gradients-new"
import TopMenu from "src/components/top_menu"
import SubpageLayout from "src/components/subpage_layout"
import SubpageFirstScreen from "src/components/subpage_first_screen"
import PriceList from "src/components/prices_list"
import Footer from "src/components/footer"
import MobileMenu from "src/components/mobile_menu"

import divisions from "src/content/divisions"

import subpageStyles from "src/css/subpage.module.css"

import brandImg from "src/images/pages/branding/image.png"
import fingerprint from "src/images/icons2/fingerprint.svg"
import gl1 from "src/images/pages/branding/gl1.png"
import nail from "src/images/pages/branding/nail.png"
import { connect } from "react-redux"

const Branding = ({isMobile}) => (
  <Layout>
    <SEO title="Stworzenie marki - Departament Reklamy" keywords={[`departament reklamy`, `elo`, `react`]} lang="pl"/>
    <GradientsNew activeGradient={1} iconBg={divisions.branding.icon}/>
    {isMobile.isMobile ? (
      <MobileMenu homeBack={true}/>
    ) : (
      <TopMenu homeBack={true}/>
    )}

    <SubpageLayout>
      <SubpageFirstScreen content={{
        title: "STWORZENIE MARKI",
        sitemap: [
          { title: "Opis", id: "id" },
          { title: "Doświadczenie", id: "id" },
          { title: "Cennik", id: "cennik" },
          { title: "Rekomendacje", id: "id" },
        ],
        slogan: "Pomysł to dopiero początek",
        img: brandImg,
        texts: {
          1: {
            title: "UTWÓRZ SPÓJNĄ MARKĘ",
            desc: "Pierwszym krokiem w tworzeniu cieszącej się sukcesem firmy, powinien byś skuteczny branding. " +
            "Rozpoznawalne logo, slogan, czy dobór własnej, unikatowej palety kolorów, " +
            "stoi za komercyjnym triumfem takich firm jak Google, Apple, czy Amazon. ",
          },
          2: {
            title: "UNIKALNOŚĆ JEST WAŻNA",
            desc: "W Departamence, wierzymy jednak w innowacyjne podejście do każdego klienta. " +
            "Podczas gdy inne firmy reklamowe używają utartych wzorców w swoich pracach, " +
            "my skupiamy się na personalnym podejściu do Twojej działalności, aby stworzyć markę, która przetrwa próbę czasu.",
          },
        },
      }}/>
      <div className={[subpageStyles.subpage_block, subpageStyles.white, subpageStyles.logos_block].join(" ")}>
        <div className={subpageStyles.items}>
          <div className={subpageStyles.item}>
            <img src={gl1}/>
          </div>
          <img src={nail} className={subpageStyles.item}/>
          {/*<div className={subpageStyles.item}/>*/}
        </div>
        <div className={subpageStyles.text_info}>
          Nasze wybrane projekty
        </div>
      </div>
      <div className={[subpageStyles.subpage_block, subpageStyles.quote_block].join(" ")}>
        Do każdego loga czy sloganu, podchodzimy jak do dzieła sztuki<br/>
        które na barkach niesie doświadczenie Twojej firmy.
      </div>
      <PriceList id="cennik" content={{
        items: [
          { title: "Identyfikacja wizualna", desc: "Logo, logotyp", price: "500 zł", prefix: "od" },
          { title: "Stworzenie marki", desc: "Logo, logotyp", price: "500 zł", prefix: "od" },
        ],
        suggestions: [divisions.papers],
      }}/>
      {isMobile.isMobile ? null : (
        <Footer/>
      )}
    </SubpageLayout>
  </Layout>
)

export default connect(state => ({
  isMobile: state.isMobile,
}), null)(Branding)
